/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import { Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import Wrapper from "components/Wrapper";
import CloseIcon from "icons/close-large.svg";
import LargeChevronIcon from "icons/chevron-large.svg";
import ChevronIcon from "icons/chevron-small.svg";
import Button from "components/Button";

export type Item = {
  name: string,
  url: string,
  children: Array<{
    name: string,
    url: string,
  }>,
};

type Props = {
  className?: string,
  items: Array<Item>,
  onItemClick?: void => void,
  resourceType: "categories" | "brands" | "partners",
};

type HeaderProps = {
  children: React$Node,
  className?: string,
  onClose: () => void,
  onBack: () => void,
};

type ItemProps = {
  className?: string,
  item: { name: string, url: string },
  children: React$Node,
};

export const SubNavHeader = ({ children, className, onClose, onBack }: HeaderProps) => {
  return (
    <Wrapper className={cn(styles.header, className)}>
      {onBack &&
        <Button className={cn(styles.button, styles.back)} onClick={onBack}>
          <LargeChevronIcon />
        </Button>
      }
      <span className={cn(styles.heading, styles.title)}>{children}</span>
      <Button className={cn(styles.button, styles.close)} onClick={onClose}>
        <CloseIcon />
      </Button>
    </Wrapper>
  );
};

export const SubNavList = ({
  items,
  className = "",
  onItemClick,
  resourceType = "categories",
}: Props) => {
  const t = useTranslate();
  const history = useHistory();
  const [subMenu, setSubMenu] = useState<string | null>(null);
  const toggleSubMenu = (name: string) => setSubMenu(subMenu === name ? null : name);

  const ItemLink = ({ item, className = styles.item, children }: ItemProps) => {
    const isParent = history.location.pathname === item.url;

    let linkState = {};
    switch (resourceType) {
      case "partners":
        linkState = { hint: { type: "partner", partner: item } };
        break;
      case "brands":
        linkState = { hint: { type: "brand", brand: item } };
        break;
      case "categories":
        linkState = { hint: { type: "category", category: item } };
        break;
      default:
        linkState = {};
    }

    return item.url === "/" ? (
      <div className={className}>
        {children}
      </div>
    ) : (
      <Link
        to={{
          pathname: item.url,
          state: linkState,
        }}
        className={className}
        onClick={() => {
          if (isParent && onItemClick) {
            onItemClick();
          }

          setSubMenu(null);
        }}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className={cn(styles.block, className)}>
      {items.map((x: Item) => (
        <div key={x.name} className={cn(styles.row, styles.parent)}>
          <Wrapper className={styles.wrapper}>
            <div
              className={cn(styles.parent__item,
                { [styles.open]: subMenu === x.name }
              )}
            >
              {x.children && x.children.length > 0 ? (
                <Button
                  className={cn(styles.button, styles.dropdownButton)}
                  onClick={() => toggleSubMenu(x.name)}
                >
                  <div className={cn(styles.name)}>
                    {x.name}
                  </div>
                  <ChevronIcon className={styles.chevron} />
                </Button>
              ) : (
                <ItemLink
                  item={x}
                  className={styles.heading}
                >
                  {x.name}
                </ItemLink>
              )}
            </div>

            <Foldable wrapperClass={styles.items} open={subMenu === x.name}>
              {Array.isArray(x.children) && (
                <ItemLink key={x.name + "-viewall"} item={x} className={styles.item}>
                  <div className={styles.item__heading}>
                    {t("HEADER.CATEGORY_SEE_ALL", {
                      categoryName: `${x.name}`,
                    })}
                  </div>
                </ItemLink>
              )}
              {Array.isArray(x.children) && x.children.map(child => (
                <ItemLink key={x.name + "-" + child.name} item={child} className={styles.item}>
                  <div className={styles.item__heading}>{child.name}</div>
                </ItemLink>
              ))}
            </Foldable>
          </Wrapper>
        </div>
      ))}
    </div>
  );
};
