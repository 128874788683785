/* @flow */

import React, { useState } from "react";
import Button from "components/Button";
import { Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import ChevronIcon from "icons/chevron-mini.svg";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  text: string,
  className?: string,
  size?: string,
};

const AdminSelectField = ({ text, className, size }: Props) => {
  const [foldableOpen, setFoldableOpen] = useState(false);
  const t = useTranslate();

  return (
    <div className={cn(className)}>
      <Button
        className={cn(styles.primaryButton, { [styles.buttonOnOpen]: foldableOpen })}
        variant="primary"
        size={size}
        onClick={() => setFoldableOpen(!foldableOpen)}
      >
        <span className={styles.text}>{text}</span>
        <ChevronIcon className={cn(styles.chevron, { [styles.chevronOnOpen]: foldableOpen })} />
      </Button>
      <Foldable className={styles.foldable} open={foldableOpen}>
        <a
          href="/partner/MemberLogin.jsp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            className={styles.button}
            variant="secondary"
            size={size}
          >
            {text}
          </Button>
        </a>
        <a
          href="/partner/MemberLogin.jsp?page=ApproveMemberList"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            className={styles.button}
            variant="secondary"
            size={size}
          >
            {t("ADMINISTRATION.APPROVE_MEMBERS")}
          </Button>
        </a>
      </Foldable>
    </div>
  );
};

export default AdminSelectField;
